import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { timeout } from 'utils/helpers';

export const useDelay = (method, timing) => {
	const [count, setCount] = useState(0);
	const [active, setActive] = useState(false);

	useEffect(() => {
		const init = async () => {
			await timeout(timing);
			method();
		};
		init();
	}, []);
};

export const useFocus = (listeners = []) => {
	const inputRef = useRef(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, [...listeners]);

	return inputRef;
};

export const useInterval = (method, listeners = []) => {
	const [count, setCount] = useState(0);

	useEffect(() => {
		const init = async () => {
			try {
				method();
				await timeout(listeners[0]);
				if (count <= listeners[1]) setCount(count + 1);
			} catch (e) {
				setCount(listeners[1]);
			}
		};
		init();
	}, [count, ...listeners]);
};

export const useMention = (comment, listeners = []) => {
	useEffect(() => {
		const card = document.getElementById(`comment_${comment.id}`);
		const mentions = card?.querySelectorAll('.mention') || [];
		mentions.forEach(anchor => {
			const a = document.createElement('a');
			a.innerHTML = anchor.innerHTML;
			anchor.getAttributeNames().forEach(attrName => {
				const attrValue = anchor.getAttribute(attrName);
				if (attrName === 'data-href') {
					a.setAttribute('href', attrValue);
					a.setAttribute('target', '_blank');
				}
				a.setAttribute(attrName, attrValue);
			});
			anchor.parentNode.replaceChild(a, anchor);
		});
	}, [...listeners]);
};

export const useRouteChange = (method, listeners = []) => {
	const router = useRouter();

	useEffect(() => {
		router.events.on('routeChangeStart', method);
		return () => router.events.off('routeChangeStart', method);
	}, [...listeners]);
};
