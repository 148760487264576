import { toTimezone } from 'utils/transforms';

export const chartLabelLookup = (interval, value) => {
	switch (interval) {
		case 'hour':
			return toTimezone(value, 'America/Denver').format('hh:mmA');

		case 'day':
			return toTimezone(value, 'America/Denver').format('MMM Do, YYYY');

		case 'week':
			return toTimezone(value, 'America/Denver').format('MMM Do, YYYY');

		case 'month':
			return toTimezone(value, 'America/Denver').format('MMM YYYY');

		case 'quarter':
			return toTimezone(value, 'America/Denver').format('MMM YYYY');

		case 'year':
			return toTimezone(value, 'America/Denver').format('YYYY');

		default:
			return toTimezone(value, 'America/Denver').format('MMM Do, YYYY');
	}
};
