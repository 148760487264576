export const buildAvatar = item => {
	const avatar = document.createElement('div');
	avatar.setAttribute('data-name', 'Avatar');
	avatar.classList.add(
		'h:bg-gray-100',
		'dark:h:bg-gray-800',
		'rounded-md',
		'cursor-pointer',
		'transition-all',
		'ease',
		'flex-start',
		'space-x-4',
		'px-2',
		'py-1',
		'w-inherit',
		'min-w-[200px]',
	);

	const img = document.createElement('img');
	img.setAttribute('data-name', 'AvatarImg');
	img.src = item.thumbnail;
	img.classList.add('border-gray-100', 'dark:border-gray-900', 'rounded-full', 'shrink-0', 'w-10', 'h-10');

	const body = document.createElement('div');
	body.setAttribute('data-name', 'AvatarBody');

	const title = document.createElement('h6');
	title.setAttribute('data-name', 'AvatarTitle');
	title.innerText = item.name;
	title.classList.add('gray-700', 'dark:gray-200', 'block', 'capitalize', 'text-base', 'font-bold', 'font-primary');

	const text = document.createElement('small');
	text.setAttribute('data-name', 'AvatarText');
	text.innerText = '@' + item.value;
	text.classList.add(
		'gray-600',
		'dark:gray-400',
		'block',
		'text-sm',
		'font-normal',
		'font-secondary',
		'leading-normal',
	);

	body.appendChild(title);
	body.appendChild(text);
	avatar.appendChild(img);
	avatar.appendChild(body);

	return avatar;
};

export const buildLoading = () => {
	const element = document.createElement('span');
	element.innerText = 'loading...';
	return element;
};
