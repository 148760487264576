import { useState } from 'react';

import { Toast, ToastBody, ToastHeader, ToastIcon, ToastText, ToastTitle } from '@playbooks/interface/toasts';

const InfoToast = ({ toast, onRemove }) => {
	const [show, setShow] = useState(true);

	// Render
	return (
		<Toast show={show} setShow={setShow} onRemove={() => onRemove(toast)}>
			<ToastHeader onRemove={() => onRemove(toast)} className='flex-start'>
				<ToastIcon icon='circle-info' color='blue-500 dark:cyan-300' />
				<ToastTitle>{toast.title}</ToastTitle>
			</ToastHeader>
			{toast.message && (
				<ToastBody>
					<ToastText>{toast.message}</ToastText>
				</ToastBody>
			)}
		</Toast>
	);
};

export { InfoToast };
