import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AppProps } from 'next/app';

import 'icons/fa-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'styles/_base.css';
import { InterfaceProvider } from '@playbooks/interface/context';
import { AccountProvider } from 'contexts/account-context';
import { AppProvider } from 'contexts/app-context';
import { IntercomProvider } from 'contexts/intercom-context';
import { LoadingProvider } from 'contexts/loading-context';
import { MixpanelProvider } from 'contexts/mixpanel-context';
import { PageContext, PageProvider } from 'contexts/page-context';
import { SearchProvider } from 'contexts/search-context';
import { SessionProvider } from 'contexts/session-context';
import { StorageProvider } from 'contexts/storage-context';
import { StoreProvider } from 'contexts/store-context';
import { StripeProvider } from 'contexts/stripe-context';
import { ThemeProvider } from 'contexts/theme-context';
import { ToastProvider } from 'contexts/toast-context';
import { isEmpty } from 'utils';
import * as logger from 'utils/logger';

const App = ({ Component, pageProps }: AppProps) => {
	// Computed
	const meta = {
		type: 'website',
		favicon: '/favicon.png',
		photo: '/seo/playbooks-cover.jpg',
		title: 'Playbooks',
		description:
			"We're building a digital marketplace where developers can buy, sell, and exchange code seamlessly. Join the hybrid source software movement.",
		card: 'summary',
		author: '@playbooksxyz',
		siteUrl: process.env.next_PUBLIC_WEB_DOMAIN,
		allowRobots: true,
	};

	// Hooks
	useEffect(() => {
		const formattedProps = {};
		Object.keys(pageProps)
			.filter(key => !['_sentryTraceData', '_sentryBaggage'].includes(key))
			.map(key => (formattedProps[key] = pageProps[key]));
		// logger.debug('env: ', process.env.NEXT_PUBLIC_NODE_ENV);
		if (!isEmpty(formattedProps)) logger.debug('ssr: ', formattedProps);
	}, [pageProps]);

	// Render
	return (
		<ToastProvider>
			<LoadingProvider>
				<StorageProvider>
					<MixpanelProvider>
						<ThemeProvider>
							<StoreProvider>
								<SessionProvider>
									<InterfaceProvider meta={meta}>
										<StripeProvider>
											<IntercomProvider>
												<SearchProvider>
													<AppProvider>
														<AccountProvider>
															<PageProvider ssr={pageProps}>
																<PageContext.Consumer>
																	{contexts => (
																		<DndProvider backend={HTML5Backend}>
																			<Component ssr={pageProps} {...contexts} />
																		</DndProvider>
																	)}
																</PageContext.Consumer>
															</PageProvider>
														</AccountProvider>
													</AppProvider>
												</SearchProvider>
											</IntercomProvider>
										</StripeProvider>
									</InterfaceProvider>
								</SessionProvider>
							</StoreProvider>
						</ThemeProvider>
					</MixpanelProvider>
				</StorageProvider>
			</LoadingProvider>
		</ToastProvider>
	);
};

export default App;
