import { useEffect, useState } from 'react';
import useWebSocket, { useEventSource } from 'react-use-websocket';

import { useToast } from 'contexts';

export const useSocket = (url, params): [sendMessage: any, lastMessage: any, loading: boolean, error: any] => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const toast = useToast();

	// Hooks
	const { sendMessage, lastMessage, readyState } = useWebSocket(url);

	useEffect(() => {
		if (readyState === 1) setTimeout(() => setLoading(false), 300);
	}, [readyState]);

	useEffect(() => {
		if (loading === false) {
			setLoading(true);
			setTimeout(() => setLoading(false), 600);
		}
	}, [JSON.stringify(params)]);

	return [sendMessage, lastMessage, loading, error];
};

export const useSource = (url, params): [method: any, loading: boolean, error: any] => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const toast = useToast();

	// Hooks
	const { lastEvent, readyState } = useEventSource(url, {
		reconnectAttempts: 10,
		reconnectInterval: 3000,
		...params,
		onClose: e => {
			setError(e);
			toast.showError({
				title: 'Request Failed',
				message: 'We were unable to connect to your logs. Please contact support.',
			});
			setTimeout(() => setLoading(false), 300);
		},
	});

	useEffect(() => {
		if (readyState === 1) setTimeout(() => setLoading(false), 300);
	}, [readyState]);

	useEffect(() => {
		if (loading === false) {
			setLoading(true);
			setTimeout(() => setLoading(false), 600);
		}
	}, [JSON.stringify(params)]);

	return [lastEvent, loading, error];
};

// https://www.npmjs.com/package/react-use-websocket
// https://developer.mozilla.org/en-US/docs/Web/API/EventSource
