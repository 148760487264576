import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useMixpanel } from 'contexts/mixpanel-context';
import { useStorage } from 'contexts/storage-context';
import { useStore } from 'contexts/store-context';
import { useToast } from 'contexts/toast-context';
import { useQuery } from 'hooks';
import * as logger from 'utils/logger';

type iSessionContext = {
	user?: any;
	loading: boolean;
	isAuthenticated: () => boolean;
	rootApi: string;
	rootLink: string;
	onAuth: any;
	onUpdate: any;
	onRefresh: any;
	onLogout: any;
	loaded?: any;
};

const SessionContext = React.createContext<iSessionContext>(null);

const SessionProvider = ({ children }) => {
	const storage = useStorage();
	const [user, setUser] = useState<any>({});
	const [loaded, setLoaded] = useState(false);
	const mixpanel = useMixpanel();
	const router = useRouter();
	const store = useStore();
	const toast = useToast();

	// Computed
	const rootApi = `/session`;
	const rootLink = `/${user.uuid}`;
	const isAuthenticated = () => (user.id ? true : false);

	// Hooks
	useEffect(() => {
		const token = storage.getValue('token');
		token.id ? fetchData() : onClear();
	}, []);

	useEffect(() => {
		if (user.id) logger.debug('sessionContext: ', user);
	}, [user]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		try {
			const response = await store.queryRecord({
				url: `/session`,
				params: {
					include: ['location', 'teams.[location]', 'setup', 'settings', 'subscription.[price, product]'],
				},
			});
			mixpanel.identify(response.data);
			mixpanel.trackEvent('Session', response.data);
			storage.storeValues({ session: response.data });
			setUser(response.data);
			setLoaded(true);
		} catch (e) {
			onClear();
		}
	});

	useEffect(() => {
		if (error) onLogout(true);
	}, [error]);

	// Methods
	const onAuth = async user => {
		const headers = { Authorization: user.token?.token };
		const response = await store.queryRecord({
			url: `/session`,
			headers,
			params: { include: ['location', 'teams.[location]', 'setup', 'settings', 'subscription.[price, product]'] },
		});
		mixpanel.identify(response.data);
		mixpanel.trackUser(response.data);
		storage.storeCookie('token', user.token?.token);
		storage.storeValues({ session: response.data, token: user.token });
		setUser(response.data);
	};

	const onUpdate = data => {
		setUser({ ...user, ...data });
		mixpanel.trackUser({ ...user, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		storage.storeValues({ account: {}, session: {}, token: {} });
		storage.removeCookies(['account', 'accountType', 'token']);
		setLoaded(true);
	};

	const onLogout = (silent = false) => {
		setUser({});
		storage.onClear();
		logger.debug('sessionContext: ', {});
		if (silent) return;
		toast.showSuccess(200, `You've been logged out.`);
		router.push('/');
	};

	// Render
	return (
		<SessionContext.Provider
			value={{
				user,
				loading,
				isAuthenticated,
				rootApi,
				rootLink,
				onAuth,
				onUpdate,
				onRefresh,
				onLogout,
				loaded,
			}}>
			{children}
		</SessionContext.Provider>
	);
};

const useSession = () => {
	return React.useContext(SessionContext);
};

export { SessionProvider, useSession };

// Docs
// https://www.npmjs.com/package/local-storage
