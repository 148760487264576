import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';

const StripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY);

const StripeContext = React.createContext({});

const StripeProvider = ({ children }) => {
	// Hooks
	useEffect(() => {}, []);

	// Methods

	// Render
	return (
		<StripeContext.Provider value={{}}>
			<Elements stripe={StripePromise}>{children}</Elements>
		</StripeContext.Provider>
	);
};

const useStripe = () => {
	return React.useContext(StripeContext);
};

export { StripeProvider, useStripe };
