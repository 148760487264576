import fetch from 'cross-fetch';
import Https from 'https';
import { env, isArray, isEmpty, isObject, timeElapsed, timeout } from 'utils';
import * as logger from 'utils/logger';

const apiDomain = process.env.NEXT_PUBLIC_API_DOMAIN;

type iAdapterRequest = {
	method?: string;
	url?: string;
	headers?: any;
	params?: any;
	data?: any | any[];
};

// Private
export const client = async (url, options = {}) => {
	const response = await fetch(url, options);
	if (!response.ok) {
		const data = await response.json();
		throw data.errors;
	}
	return await response.json();
};

export const formatOptions = (method, headers, data?) => {
	const formattedOptions = {};
	formattedOptions['method'] = method;
	formattedOptions['headers'] = { ['Content-Type']: 'application/json', ...headers };
	formattedOptions['body'] = JSON.stringify(data);
	if (env === 'development') formattedOptions['agent'] = new Https.Agent({ rejectUnauthorized: false });
	return formattedOptions;
};

export const formatUrl = (url, params) => {
	const formattedUrl = new URL(apiDomain + url);
	Object.keys(params).map(key => {
		const value = params[key];
		if (isEmpty(value)) return;
		if (isArray(value)) return formattedUrl.searchParams.append(key, value.join(','));
		if (isObject(value)) return Object.keys(value).map(key => formattedUrl.searchParams.append(key, value[key]));
		return formattedUrl.searchParams.append(key, value);
	});
	return formattedUrl;
};

export const buildRequest = async ({ method = 'GET', url, headers, params, data }: iAdapterRequest) => {
	const date = new Date();
	const formattedUrl = formatUrl(url, params);
	const formattedOptions = formatOptions(method, headers, data);
	// console.log('options: ', formattedOptions);
	const response = await client(formattedUrl, formattedOptions);
	return [timeElapsed(date), response];
};

export const apiRequest = async ({ method = 'GET', url, headers, params, data }: iAdapterRequest) => {
	await timeout(300);
	logger.info(`apiRequest: `, { method, url, params, data });
	const [date, response] = await buildRequest({ method, url, headers, params, data });
	logger.info(`apiResponse (${date}): `, { method, url, params, response });
	return response;
};

export const storeRequest = async ({ method = 'GET', url, headers, params, data }: iAdapterRequest) => {
	await timeout(300);
	logger.info(`storeRequest: `, { method, url, params, data });
	const [date, response] = await buildRequest({ method, url, headers, params, data });
	logger.info(`storeResponse (${date}): `, { method, url, params, response });
	return response;
};

export const downloadRequest = async ({ method = 'GET', url, headers, params, data }: iAdapterRequest) => {
	await timeout(300);
	const date = new Date();
	logger.info(`downloadRequest: `, { method, url, params, data });
	const formattedUrl = formatUrl(url, params);
	const formattedOptions = formatOptions(method, headers, data);
	const response = await fetch(formattedUrl, formattedOptions);
	logger.info(`downloadResponse (${date}): `, { method, url, params, response });
	if (!response.ok) {
		const data = await response.json();
		throw data.errors;
	}
	return await response;
};

// Docs:
// https://github.github.io/fetch/
// https://www.npmjs.com/package/cross-fetch#usage
// https://developer.mozilla.org/en-US/docs/Web/API/URL
// https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
