import React, { useEffect, useState } from 'react';

import { useQuery } from 'hooks';
import * as logger from 'utils/logger';

type iStackContext = {
	stack?: any;
	setStack?: any;
	rootApi: string;
	secureApi: string;
	rootLink: string;
	isOwner: boolean;
	loading: boolean;
	onUpdate: any;
	onRefresh: any;
};

const StackContext = React.createContext<iStackContext>(null);

const StackProvider = ({ ssr, account, session, store, toast, children }) => {
	const [stack, setStack] = useState(ssr.stack?.data || {});
	const [loaded, setLoaded] = useState(false);

	// Computed
	const rootApi = `/stacks/${stack.uuid}`;
	const rootLink = `/${stack.owner?.uuid}`;
	const secureApi = `${account.rootApi}/stacks/${stack.uuid}`;
	const isOwner = account.account?.id === stack.ownerId && account.type === stack.ownerType;

	// Hooks
	useEffect(() => {
		setLoaded(true);
		return () => onClear();
	}, []);

	useEffect(() => {
		if (loaded) setStack(ssr.stack?.data || {});
	}, [ssr?.stack]);

	useEffect(() => {
		if (stack.id) logger.debug('stackContext: ', stack);
	}, [stack]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		const response = await store.queryRecord({ url: `/stacks/${stack.uuid}` });
		setStack(response.data);
	});

	// Methods
	const onUpdate = data => {
		setStack({ ...stack, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		setStack({});
	};

	// Render
	return (
		<StackContext.Provider
			value={{ stack, setStack, isOwner, rootApi, rootLink, secureApi, loading, onUpdate, onRefresh }}>
			{children}
		</StackContext.Provider>
	);
};

const useStack = () => {
	return React.useContext(StackContext);
};

export { StackProvider, useStack };
