import { useState } from 'react';

import { Toast, ToastBody, ToastHeader, ToastIcon, ToastText, ToastTitle } from '@playbooks/interface/toasts';

const SuccessToast = ({ toast, onRemove }) => {
	const [show, setShow] = useState(true);

	// Render
	return (
		<Toast show={show} setShow={setShow} onRemove={() => onRemove(toast)}>
			<ToastHeader onRemove={() => onRemove(toast)}>
				<ToastIcon icon='circle-check' color='blue-500 dark:green-400' />
				<ToastTitle>{toast.title}</ToastTitle>
			</ToastHeader>
			{toast.message && (
				<ToastBody>
					<ToastText>{toast.message}</ToastText>
				</ToastBody>
			)}
		</Toast>
	);
};

export { SuccessToast };
