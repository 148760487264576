import React, { useEffect, useState } from 'react';

import { submissionDetailParams } from 'api';
import { useQuery } from 'hooks';
import * as logger from 'utils/logger';

type SubmissionContext = {
	submission: any;
	setSubmission: any;
	isOwner: boolean;
	rootApi: string;
	rootLink: string;
	secureApi: string;
	loading: boolean;
	onUpdate: any;
	onRefresh: any;
};

export const SubmissionContext = React.createContext<SubmissionContext>(null);

const SubmissionProvider = ({ ssr, account, router, session, store, toast, children }) => {
	const [submission, setSubmission] = useState(ssr.submission?.data || {});
	const [loaded, setLoaded] = useState(false);

	// Computed
	const rootApi = `/submissions/${submission.uuid}`;
	const rootLink = `/submissions/${submission.uuid}`;
	const secureApi = `${account.rootApi}/submissions/${submission.uuid}`;
	const isOwner = account.account?.id === submission.ownerId && account.type === submission.ownerType;

	// Hooks
	useEffect(() => {
		setLoaded(true);
		return () => onClear();
	}, []);

	useEffect(() => {
		if (loaded) setSubmission(ssr.submission?.data || {});
	}, [ssr.submission]);

	useEffect(() => {
		if (router.asPath?.includes('/settings') && !isOwner) router.push('/403');
	}, [router.pathname]);

	useEffect(() => {
		if (submission.id) logger.debug('submissionContext: ', submission);
	}, [submission]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		const response = await store.queryRecord({
			url: `/submissions/${submission.uuid}`,
			params: submissionDetailParams,
		});
		setSubmission(response.data);
	});

	// Methods
	const onUpdate = data => {
		setSubmission({ ...submission, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => setSubmission({});

	// Render
	return (
		<SubmissionContext.Provider
			value={{ submission, setSubmission, isOwner, rootApi, rootLink, secureApi, loading, onUpdate, onRefresh }}>
			{children}
		</SubmissionContext.Provider>
	);
};

const useSubmission = () => {
	return React.useContext(SubmissionContext);
};

export { SubmissionProvider, useSubmission };
