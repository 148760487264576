import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAccount } from 'contexts/account-context';
import { AdminProvider } from 'contexts/admin-context';
import { useApp } from 'contexts/app-context';
import { BountyContext, BountyProvider } from 'contexts/bounty-context';
import { DiscussionProvider } from 'contexts/discussion-context';
import { RepoContext, RepoProvider } from 'contexts/repo-context';
import { StackProvider } from 'contexts/stack-context';
import { SubmissionContext, SubmissionProvider } from 'contexts/submission-context';
import { SubmitBountyProvider } from 'contexts/submit-bounty-context';
import { SubmitRepoProvider } from 'contexts/submit-repo-context';
import { SubmitStackProvider } from 'contexts/submit-stack-context';
import { SubmitSubmissionProvider } from 'contexts/submit-submission-context';
import { TeamProvider } from 'contexts/team-context';
import { UserProvider } from 'contexts/user-context';

export const PageContext = React.createContext(null);

const PageProvider = ({ ssr, children }) => {
	const { session, store, storage, toast } = useApp();
	const account = useAccount();
	const router = useRouter();
	const contexts = { ssr, account, router, session, store, storage, toast, children };

	// Hooks
	useEffect(() => {
		window.scrollTo(0, 0);
		// logger.log('pathname: ', router.asPath);
	}, [router.pathname]);

	// Render
	if (router.pathname.includes('/admin')) {
		return (
			<AdminProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</AdminProvider>
		);
	}
	if (router.pathname.includes('/bounties/[bountyId]')) {
		if (router.pathname.includes('/[discussionId]')) {
			return (
				<BountyProvider {...contexts}>
					<BountyContext.Consumer>
						{context => (
							<DiscussionProvider type='Bounty' entity={context} {...contexts}>
								<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
							</DiscussionProvider>
						)}
					</BountyContext.Consumer>
				</BountyProvider>
			);
		}
		if (router.pathname.includes('/add/[submissionId]')) {
			return (
				<BountyProvider {...contexts}>
					<BountyContext.Consumer>
						{context => (
							<SubmitSubmissionProvider entity={context} {...contexts}>
								<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
							</SubmitSubmissionProvider>
						)}
					</BountyContext.Consumer>
				</BountyProvider>
			);
		}
		return (
			<BountyProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</BountyProvider>
		);
	}
	if (
		router.pathname.includes('/stacks/[stackId]') &&
		!router.pathname.includes('[teamId]') &&
		!router.pathname.includes('[uuid]')
	) {
		return (
			<StackProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</StackProvider>
		);
	}
	if (router.pathname.includes('/repos/[repoId]')) {
		if (router.pathname.includes('/[discussionId]')) {
			return (
				<RepoProvider {...contexts}>
					<RepoContext.Consumer>
						{context => (
							<DiscussionProvider type='Repo' entity={context} {...contexts}>
								<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
							</DiscussionProvider>
						)}
					</RepoContext.Consumer>
				</RepoProvider>
			);
		}
		return (
			<RepoProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</RepoProvider>
		);
	}
	if (router.pathname.includes('/submissions/[submissionId]')) {
		if (router.pathname.includes('/discussion')) {
			return (
				<SubmissionProvider {...contexts}>
					<SubmissionContext.Consumer>
						{context => (
							<DiscussionProvider type='Submission' entity={context} {...contexts}>
								<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
							</DiscussionProvider>
						)}
					</SubmissionContext.Consumer>
				</SubmissionProvider>
			);
		}
		return (
			<SubmissionProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</SubmissionProvider>
		);
	}
	if (router.pathname.includes('/add/bounty/[bountyId]')) {
		return (
			<SubmitBountyProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</SubmitBountyProvider>
		);
	}
	if (router.pathname.includes('/add/stack/[stackId]')) {
		return (
			<SubmitStackProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</SubmitStackProvider>
		);
	}
	if (router.pathname.includes('/add/repo/[repoId]')) {
		return (
			<SubmitRepoProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</SubmitRepoProvider>
		);
	}
	if (router.pathname.includes('/teams/[teamId]')) {
		if (router.pathname.includes('/[stackId]')) {
			return (
				<TeamProvider {...contexts}>
					<StackProvider {...contexts}>
						<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
					</StackProvider>
				</TeamProvider>
			);
		}
		return (
			<TeamProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</TeamProvider>
		);
	}
	if (router.pathname.includes('/[uuid]')) {
		if (router.pathname.includes('/[stackId]')) {
			return (
				<UserProvider {...contexts}>
					<StackProvider {...contexts}>
						<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
					</StackProvider>
				</UserProvider>
			);
		}
		return (
			<UserProvider {...contexts}>
				<PageContext.Provider value={contexts}>{children}</PageContext.Provider>
			</UserProvider>
		);
	}
	return <PageContext.Provider value={contexts}>{children}</PageContext.Provider>;
};

export { PageProvider };
