import Accounting from 'accounting';
import Dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Timezone from 'dayjs/plugin/timezone';
import UTC from 'dayjs/plugin/utc';
import { abbreviateNumber } from 'js-abbreviation-number';

Dayjs.extend(AdvancedFormat);
Dayjs.extend(QuarterOfYear);
Dayjs.extend(RelativeTime);
Dayjs.extend(Timezone);
Dayjs.extend(UTC);

export const camelToUnderscore = (data = '') => {
	return data.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const camelToDash = (data = '') => {
	return data.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

export const camelToString = (data = '') => {
	return data.replace(/([A-Z])/g, ' $1').toLowerCase();
};

export const dashToCamel = (data = '') => {
	return data.replace(/-([a-z])/g, g => g[1].toUpperCase());
};

export const dashToCapital = (data = '') => {
	return data
		.split('-')
		.map(k => k.replace(k.charAt(0), k.charAt(0).toUpperCase()))
		.join('');
};

export const dashToString = (data = '') => {
	return data.replace('-', ' ');
};

export const stringToDash = (data = '') => {
	return data.replace(' ', '-');
};

export const phoneToString = (data = '') => {
	return data ? data.replace(/\D+/g, '') : '';
};

export const capitalize = (data = '') => {
	return data?.charAt(0).toUpperCase() + data?.slice(1);
};

export const deCapitalize = (data = '') => {
	return data?.charAt(0).toLowerCase() + data?.slice(1);
};

export const stringToUnderscore = (data = '') => {
	return data.split(' ').join('_').toLowerCase();
};

export const underscoreToString = (data = '') => {
	return data ? data.split('_').join(' ').toLowerCase() : data;
};

export const truncate = (data = '', count = 80, ellipsis = true) => {
	return data ? (data.length >= count && ellipsis ? data.slice(0, count) + '...' : data.slice(0, count)) : null;
};

export const toPhone = (value = '') => {
	if (value.length === 10) {
		return value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
	}
	return '--';
};

export const toCurrency = (value, precision = 2) => {
	return Accounting.formatMoney(value, '$', precision);
};

export const toPercent = (value = 0) => {
	return value * 100 + '%';
};

export const toNumber = (value, decimal = 0) => {
	return Accounting.formatNumber(value, decimal);
};

export const toShortNumber = (value = 0, decimal = 1) => {
	return abbreviateNumber(value, decimal);
};

export const toCents = (value = '') => {
	return value ? parseFloat(value) * 100 : 0;
};

export const fromCents = (value = 0) => {
	return value ? value / 100 : 0;
};

export const toDayjs = (value?) => {
	return Dayjs(value);
};

export const toTimezone = (value: string, timezone = 'America/Denver') => {
	return Dayjs.tz(value, timezone);
};

export const formatDate = (value: any, format?: string) => {
	return Dayjs(value).format(format || 'MMM Do, YYYY @ hh:mmA');
};

export const formatTimestamp = (value?: any) => {
	return Dayjs(value).fromNow(true);
};

export const toDate = (value?: any) => {
	return Dayjs(value).toDate();
};

export const toJson = (value?: any) => {
	return Dayjs(value).toJSON();
};

export const toYear = (value?: any) => {
	return Dayjs(value).year();
};

export const toUnix = (value?: any) => {
	return Dayjs(value).valueOf();
};

export const fromUnix = (value: number) => {
	return Dayjs.unix(value);
};
