import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import Intercom from '@intercom/messenger-js-sdk';
import { useSession } from 'contexts/session-context';

type iIntercom = {
	open: boolean;
	toggle: any;
	start: any;
	show: any;
	hide: any;
	loaded: boolean;
};

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

const IntercomContext = React.createContext<iIntercom>(null);

const IntercomProvider = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const router = useRouter();
	const session = useSession();

	// Hooks
	useEffect(() => {
		if (session.loaded) init();
	}, [session.loaded]);

	useEffect(() => {
		if (session.user.id && loaded) update();
	}, [session.user.id]);

	useEffect(() => {
		if (open) window.Intercom('onHide', () => shutdown());
	}, [open]);

	useEffect(() => {
		if (window.Intercom && loaded) hide();
	}, [router.pathname]);

	// Methods
	const init = () => {
		setLoaded(true);
	};

	const update = () => {
		Intercom({
			name: session.user?.name,
			email: session.user?.email,
			app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
		});
	};

	const toggle = () => {
		open ? hide() : start();
	};

	const start = () => {
		update();
		show();
		setOpen(true);
	};

	const show = () => {
		window.Intercom('show');
	};

	const showNew = () => {
		window.Intercom('showNewMessage');
	};

	const hide = () => {
		window.Intercom('hide');
	};

	const shutdown = () => {
		window.Intercom('shutdown');
		setOpen(false);
	};

	// Render
	return (
		<IntercomContext.Provider value={{ open, toggle, start, show, hide, loaded }}>{children}</IntercomContext.Provider>
	);
};

const useIntercom = () => {
	return React.useContext(IntercomContext);
};

export { IntercomProvider, useIntercom };

// Docs
// https://developers.intercom.com/installing-intercom/web
// https://developers.intercom.com/installing-intercom/web/installation
// https://developers.intercom.com/installing-intercom/web/methods
// https://developers.intercom.com/installing-intercom/web/attributes-objects
