import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { useStorage, useStore } from 'contexts';

export const SearchContext = React.createContext(null);

const SearchProvider = ({ children }) => {
	const [showFilters, setShowFilters] = useState(true);
	const [showMobileFilters, setShowMobileFilters] = useState(false);
	const router = useRouter();
	const storage = useStorage();
	const store = useStore();

	// Computed
	const isAdmin = useMemo(() => {
		return router.pathname.includes('/admin') ? true : false;
	}, [router.pathname]);

	// Hooks
	useEffect(() => {}, []);

	// Methods
	const saveQuery = (modelName, query, result) => {
		try {
			const modelId = result?.id;
			const formattedQuery = query.toLowerCase();
			storage.pushSearchValue('search', { modelId, modelName, query: formattedQuery, result, date: new Date() });
			store.saveRecord({ url: '/searches', data: { modelId, modelName, query: formattedQuery, result } });
		} catch (e) {
			console.error(e);
		}
	};

	const onClick = (rootLink, result) => {
		switch (result.index) {
			case 'bounties':
				saveQuery('bounties', result.name, result);
				return router.push(`${rootLink}/bounties/${result.uuid}`);

			case 'frameworks':
				saveQuery('frameworks', result.name, result);
				return router.push(`${rootLink}/frameworks/${result.uuid}`);

			case 'languages':
				saveQuery('languages', result.name, result);
				return router.push(`${rootLink}/languages/${result.uuid}`);

			case 'teams':
				saveQuery('teams', result.name, result);
				return router.push(`${rootLink}/teams/${result.uuid}`);

			case 'repos':
				saveQuery('repos', result.name, result);
				return router.push(`${rootLink}/repos/${result.uuid}`);

			case 'stacks':
				saveQuery('stacks', result.name, result);
				return router.push(`${rootLink}/stacks/${result.uuid}`);

			case 'tools':
				saveQuery('tools', result.name, result);
				return router.push(`${rootLink}/tools/${result.uuid}`);

			case 'topics':
				saveQuery('topics', result.name, result);
				return router.push(`${rootLink}/topics/${result.uuid}`);

			case 'users':
				saveQuery('users', result.name, result);
				if (isAdmin) return router.push(`${rootLink}/users/${result.uuid}`);
				return router.push(`${rootLink}/${result.uuid}`);
		}
	};
	3;

	// Render
	return (
		<SearchContext.Provider
			value={{ saveQuery, onClick, showFilters, setShowFilters, showMobileFilters, setShowMobileFilters }}>
			{children}
		</SearchContext.Provider>
	);
};

const useSearch = () => {
	return React.useContext(SearchContext);
};

export { SearchProvider, useSearch };

// We could switch render depending on route.
